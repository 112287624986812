/** @jsx jsx */
import React from "react";
import { Helmet } from "react-helmet";
import { Box, Container, jsx } from "theme-ui";
import PrimaryLayout from "../components/layout/primary/primary";
import LoginForm from "../components/login/login-form";
import SEO from "../components/seo";
import { getJwtToken } from "../services/auth-service";

const styles = {
  wrapper: {
    padding: 30,
    marginBottom: 30,
    borderRadius: 6,
    backgroundColor: "white",
    ":last-child": {
      marginBottom: 0,
    },
    h1: {
      textAlign: "center",
    },
    border: "1px solid #e4e4e4",
    margin: "0 auto",
    "@media only screen and (max-width: 768px)": {
      padding: 20,
    },
  },
  container: {
    "@media only screen and (max-width: 768px)": {
      paddingLeft: "10px!important",
      paddingRight: "10px!important",
    },
  },
};

const LoginPage: React.FunctionComponent<{}> = ({}: any) => {
  if (getJwtToken() !== null) window.location.href = "/account";

  return (
    <PrimaryLayout fluid={true}>
      <SEO title="Login" description="The Wise Root customer login" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container py="40px" sx={styles.container}>
        <div className="row">
          <div sx={styles.wrapper} className="col-lg-5">
            <Box>
              <h1>Log In</h1>
              <hr className="bar-indent-center" />
              <LoginForm />
            </Box>
          </div>
        </div>
      </Container>
    </PrimaryLayout>
  );
};
export default LoginPage;
