const styles = {
	input: {
		width: '100%',
		fontSize: 1,
		fontWight: '400',
		borderRadius: 6,
		padding: ['14px 20px 14px 10px', '14px 25px 14px 10px'],
		marginBottom: '10px',
		backgroundColor: 'lightergray',
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: 'muted',
		boxShadow: 'none',
		transition: 'border-color 0.2s ease',
		'&:focus': {
			outline: 'none',
			boxShadow: 'none',
			borderColor: 'darkgray',
		},
		error: {
			fontSize: '13px',
			marginTop: '-5px',
			paddingBottom: '5px',
			color: '#dc3545'
		}
	},
	passwordResetLink: {
		textDecoration: 'none!important',
		paddingBottom: '20px',
		float:'right'
	},
	loginButton: {
		backgroundColor: '#00d4c5',
		color: 'white',
		float:'right',
		minWidth: 0,
		fontWeight: 'normal',
		borderRadius:'10px',
		width: '100%',
		link: {
			width: '100%'
		}
	},
	section: {
		register: {
			marginTop: 20
		}
	}
};

export default styles;
