/** @jsx jsx */
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Button, jsx } from "theme-ui";
import * as Yup from "yup";
import { Login } from "../../services/user-service";
import styles from "./login.style";

const LoginFormSchema = Yup.object().shape({
  email: Yup.string()
    .email("Your email does not appear to be correct")
    .required("Please enter your email address"),
  password: Yup.string().required("Please enter your password"),
});

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={LoginFormSchema}
      onSubmit={async (values: any, { setSubmitting }: any) => {
        setLoading(true);

        await Login(values).then((result: React.SetStateAction<string>) => {
          setMessage(result);
        });

        setLoading(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form id="LoginForm">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <Field
                  sx={styles.input}
                  type="text"
                  name="email"
                  id="email"
                  className="form-control"
                  placeholder="Please enter your email"
                />
                <ErrorMessage
                  sx={styles.input.error}
                  name="email"
                  component="div"
                />
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <Field
                  sx={styles.input}
                  type="password"
                  name="password"
                  id="password"
                  className="form-control"
                  placeholder="Please enter your password"
                />
                <ErrorMessage
                  sx={styles.input.error}
                  name="password"
                  component="div"
                />
              </div>
              {message !== "" && (
                <p className={"text-center text-danger"}>{message}</p>
              )}
            </div>
            <div className="col-lg-12 col-md-12">
              <a href="mailto:outible@admin.com" sx={styles.passwordResetLink}>
                Forgotten password
              </a>
              <Button
                sx={styles.loginButton}
                id="submitLoginForm"
                type="submit"
                disabled={isSubmitting}
              >
                {!loading && <span id="login">Log In</span>}
                {loading && (
                  <Spinner animation="border" role="status">
                    <span className="sr-only"></span>
                  </Spinner>
                )}
              </Button>
            </div>

            <small>
              {" "}
              <br />
              <i>
                This site's usage, including attempted usage, is tracked. Any
                attempt at unauthorised use could lead to criminal prosecution.
              </i>
            </small>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
